export default function (string: any) {
  const checkBase64 = _isString(string) ? string.includes('data:') : false

  let url

  try {
    url = new URL(string)
  }
  // eslint-disable-next-line unused-imports/no-unused-vars
  catch (_) {
    return checkBase64
  }

  return url.protocol === 'http:' || url.protocol === 'https:' || checkBase64
}
